import QrcodeMealRepository from '@/shared/http/repositories/socialProject/qrcode-meals'
import AccessDeniedSound from '@/assets/audios/qrcode/qrcode-access-denied.wav'
import AccessAllowedSound from '@/assets/audios/qrcode/qrcode-access-allowed.wav'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import QrCodeMealCriteria from '@/shared/models/criteria/qrcodemealcriteria'
import { customDateFilter } from '@/shared/filters/ToCustomDate'
import QRCode from '@/components/layout/QRCode/QRCode.vue'
import download from 'downloadjs'
import moment from 'moment'
import { setInterval } from 'core-js'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    QRCode,
  },

  name: 'Meals',

  data: () => ({
    criteria: new QrCodeMealCriteria(),
    readerReady: true,
    qrcode: null,
    showModalReadQRCode: true,
    showModalInsertManually: false,
    documentRG: null,
    lastTenMeals: [],
    qrCodeMeals: [],
    emptyTextQrcodeMeal: 'Nenhuma refeição ainda hoje..',
    emptyTextQrcodeMealList: 'Nenhuma refeição cadastrada',
    totalMeal: 0,
    dateStartFormatted: null,
    dateEndFormatted: null,
    showModalResultReader: false,
    responseType: null,
    typeSelected: null,
    selected: {},
    showModalDeleteMeals: false,
    showModalCreateQRCodeSingle: false,
    isSingle: true,
    seconds: 3,
    fieldsQrCodeMeal: [
      {
        key: 'name',
				label: 'PORTADOR',
				class: 'left-header-border text-center left-header-border'
      },
      {
        key: 'typeQRCode',
				label: 'FUNÇÃO',
				class: 'middle-header text-center'
      },
      {
        key: 'mealDate',
				label: 'DATA DA LEITURA',
				class: 'right-header-border text-center',
        formatter: (value) => {
          if (!value) return 'N/D'

          const dateformatted = moment(value).format("DD/MM/YYYY HH:mm")
          return dateformatted
        }
      }
    ],
    fieldsQrCodeMealList: [
      {
        key: 'name',
				label: 'PORTADOR',
				class: 'left-header-border text-center left-header-border'
      },
      {
        key: 'typeQRCode',
				label: 'FUNÇÃO',
				class: 'middle-header text-center'
      },
      {
        key: 'documentRG',
				label: 'RG',
				class: 'middle-header text-center',
        formatter:(value) => {
          if (!value) return '-'
          
          return value
        }
      },
      {
        key: 'meal',
				label: 'QNTD DE REFEIÇÃO RETIRADA',
				class: 'middle-header text-center',
      },
      {
        key: 'valueMeal',
				label: 'VALOR DA REFEIÇÃO R$',
				class: 'middle-header text-center',
        formatter:(value) => {
          if (!value || value == 0) return 'R$ 0'
          
          return 'R$ ' + value
        }
      },
      {
        key: 'mealDate',
				label: 'DATA DA REFEIÇÃO',
				class: 'middle-header text-center',
        formatter:(value) => {
          const dateformatted = moment(value).format("DD/MM/YYYY HH:mm")
          return dateformatted
        }
      },
      {
        key: 'action',
				label: 'AÇÕES',
				class: 'right-header-border text-center'
      }
    ],
    paginationMeal: {
      count: 1,
      limit: 20,
      index: 1
    }
  }),

  filters: {
    formattedDate(value) {
      if (!value) return 'SEM DATA'
      const dateformatted = moment(value).format("DD/MM/YYYY HH:mm")
      return dateformatted
    }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Refeições',
            active: true
          }
        ]
      }
		},
    getCurrentDate() {
      return moment().format("DD/MM/YYYY")
    }
	},

  created() {
    this.GetLastTenMeals()
  },

  watch: {
    showModalResultReader: {
      handler: function (value) {
        if (!value) return

        this.timeOut()
      },
      immediate: true
    }
  },

  methods: {
    runSound(file) {
      if(!file) return

      let audio = new Audio(file)
      audio.play();
    },

    timeOut() {
      this.seconds = 3
      let obj = setInterval(() => {
        this.seconds--

        if (this.seconds == 0) {
          this.showModalResultReader = false
          this.qrcode = null
          clearInterval(obj)
          return this.seconds
        }
        
        return this.seconds
      }, 500)
    },

    onInsertManually() {
      this.showModalReadQRCode = false
      this.showModalInsertManually = true
      this.onFocusInserRG()
    },

    inserManually() {
      this.readerReady = false
      this.responseType = null
      
      if (this.documentRG == null || this.documentRG.trim() == '') {
        toast.info('Insira um RG', 'LEITOR DE QRCODE')
        this.documentRG = null
        this.readerReady = true
        return
      }
      
      loading.push()
      QrcodeMealRepository.SaveQRCodeMealByRG(this.documentRG)
        .then(res => {
          if (!IsSuccessCode(res)) {
            const { code } = res.data
            this.responseType = code
            
            if (code === -1001 || code === -1002) {
              loading.pop()
              this.runSound(AccessDeniedSound)
              this.GetLastTenMeals()

              this.documentRG = null
              this.readerReady = true
              this.showModalInsertManually = false
              this.showModalReadQRCode = true
              this.showModalResultReader = true
              return
            }

            return Promise.reject()
          }

          this.GetLastTenMeals()
          this.runSound(AccessAllowedSound)
          this.documentRG = null
          this.readerReady = true
          this.showModalInsertManually = false
          this.showModalReadQRCode = true
          this.showModalResultReader = true

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar entrada da refeição', 'ERRO')
        })
    },

    onSaveQRCodeMealByID(id) {
      this.readerReady = false
      this.responseType = null
      
      if (id == null || id.length != 36) {
        this.runSound(AccessDeniedSound)
        this.responseType = -1001
        this.showModalResultReader = true
        this.qrcode = null
        this.readerReady = true
        return
      }
      
      loading.push()
      QrcodeMealRepository.SaveQRCodeMealByID(id)
        .then(res => {
          if (!IsSuccessCode(res)) {
            const { code } = res.data
            this.responseType = code
            
            if (code === -1001 || code === -1002) {
              loading.pop()
              this.GetLastTenMeals()
              this.runSound(AccessDeniedSound)

              this.qrcode = null
              this.readerReady = true
              this.showModalResultReader = true
              return
            }

            return Promise.reject()
          }

          this.GetLastTenMeals()
          this.runSound(AccessAllowedSound)
          this.qrcode = null
          this.readerReady = true
          this.showModalResultReader = true

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar entrada da refeição', 'ERRO')
        })
    },

    GetLastTenMeals() {
      loading.push()
      QrcodeMealRepository.GetLastTenMeals()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          this.lastTenMeals = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar os 10 primeiros registros', 'ERRO')
        })
    },

    getAll() {
      loading.push()
      QrcodeMealRepository.GetAll(this.criteria, this.paginationMeal)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          this.qrCodeMeals = res.data.data
          this.totalMeal = res.data.count

          this.paginationMeal = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar as refeições', 'ERRO')
        })
    },

    downloadExcel() {
      const criteriaNoHasValue = !this.criteria
      if (criteriaNoHasValue) {
        toast.info('Preencha alguma informação antes de montar o relatório', 'RELATÓRIO DE REUNIÃO')
        return
      }

      loading.push()
      QrcodeMealRepository.ExportExcelMeals(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          const haveData = this.qrCodeMeals.length
          if(haveData == 0) {
            toast.warning('Realize uma pesquisa com informações para depois gerar uma planilha', 'RELATÓRIO REFEIÇÕES')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    onFocusReadQRCode() {
        let input = document.getElementById('input-read-qrcode')
        this.readerReady = false
        input.setAttribute('readonly', true)
        
        setTimeout(() => {
        input.removeAttribute('readonly', false)
        input.focus()
        this.readerReady = true
      }, 150);
    },

    onFocusInserRG() {
      this.readerReady = false
      setTimeout(() => {
        document.getElementById('input-read-rg').focus()
        this.readerReady = true
      }, 350);
    },
    
    onReadQRCode() {
      this.showModalReadQRCode = true
      this.onFocusReadQRCode()
    },

    closeModalReadQRCode() {
      this.showModalReadQRCode = false
      this.qrcode = null
    },
    closeModalInsertManually() {
      this.showModalInsertManually = false
      this.showModalReadQRCode = true
      this.documentRG = null
      this.onFocusReadQRCode()
    },

    onSetTypeQrCode(type) {
      if (type === 'employee') {
        this.typeSelected = 'Funcionário'
        this.criteria.qrCodeType = 'employee'
        return
      }
      
      if (type === 'single') {
        this.typeSelected = 'Visitante'
        this.criteria.qrCodeType = 'single'
        return
      }

      if (type === 'student') {
        this.typeSelected = 'Estudante'
        this.criteria.qrCodeType = 'student'
        return
      }

      if (type === 'teacher') {
        this.typeSelected = 'Professor'
        this.criteria.qrCodeType = 'teacher'
        return
      }

      if (type === 'clear') {
        this.typeSelected = null
        this.criteria.qrCodeType = null
        return
      }
    },

    selectRow(item) {
      if  (!item) return

      this.selected = item
      this.id = item.id
      this.showModalDeleteMeals = true
    },

    onDeleteMeal() {
      loading.push()
      QrcodeMealRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Refeição excluída com sucesso!', 'EXCLUSÃO')
          this.closeModalDeleteMeals()
          this.GetLastTenMeals()
          this.getAll()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir Refeição', 'ERRO')
        })
    },

    onGenerateQRCodeSingle() {
      this.showModalCreateQRCodeSingle = true
    },

    onCloseModalQrCode() {
      this.showModalCreateQRCodeSingle = false
    },

    closeModalDeleteMeals() {
      this.selected = {}
      this.showModalDeleteMeals = false
    },

    onContextDateStart(ctx) {
      this.dateStartFormatted = ctx.selectedFormatted
    },

    onContextDateEnd(ctx) {
      this.dateEndFormatted = ctx.selectedFormatted
    },

    formatDateStart(event) {
      this.criteria.dateStart = customDateFilter(event.currentTarget.value)
    },

    formatDateEnd(event) {
      this.criteria.dateEnd = customDateFilter(event.currentTarget.value)
    },
  }
}
