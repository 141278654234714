export default class QrCodeMealCriteria {
    constructor({
      name,
      documentRG,
      dateStart,
      dateEnd,
      qrCodeType
    } = {}) {
      this.name = name
      this.documentRG = documentRG
      this.dateStart = dateStart
      this.dateEnd = dateEnd
      this.qrCodeType = qrCodeType
    }
  }