import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'qrcodemeal'

const GetAll = (data, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(data, pagination) 
})
const GetLastTenMeals = () => SocialProjectManager.get(`${resourceName}/ten-meals`,)
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)
const UpdateQRCode = data => SocialProjectManager.put(`${resourceName}/update`, data)
const SaveQRCodeMealByRG = data => SocialProjectManager.post(`${resourceName}/meal-rg/${data}`,)
const SaveQRCodeMealByID = data => SocialProjectManager.post(`${resourceName}/meal/${data}`,)
const ExportExcelMeals = criteria => SocialProjectManager.get(`${resourceName}/download-excel`, { params: criteria })

const GetValueMeal = () => SocialProjectManager.get(`${resourceName}/get-value-meal`,)
const PostValueMeal = data => SocialProjectManager.post(`${resourceName}/post-value-meal`, data)

const QRCodeMealRepository = {
  GetAll,
  GetById,
  SaveQRCodeMealByRG,
  Delete,
  UpdateQRCode,
  GetLastTenMeals,
  ExportExcelMeals,
  SaveQRCodeMealByID,

  GetValueMeal,
  PostValueMeal
}

export default QRCodeMealRepository